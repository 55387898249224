import I18n from '../i18n';
import axios from 'axios';

interface Tokens {
  access_token: string;
  access_token_lifespan: number;
  refresh_token: string;
}

export async function postLogin(email: string, code: string): Promise<Tokens | undefined> {
  try {
    const res = await axios.post(
      '/api/auth',
      {
        grant_type: 'password',
        username: email,
        password: code,
        client_id: 'solar',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}

export async function postRefresh(refreshToken: string): Promise<Tokens | undefined> {
  try {
    const res = await axios.post(
      '/api/auth',
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: 'solar',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}

export async function postRequestLoginToken(email: string): Promise<void> {
  try {
    await axios.post(
      '/api/auth/login_token',
      {
        connection: 'email',
        email,
        client_id: 'solar',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': I18n.currentLocale(),
        },
      },
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}

export async function postRevokeRefreshToken(accessToken: string, refreshToken: string): Promise<void> {
  try {
    await axios.post(
      '/api/auth/revoke',
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': I18n.currentLocale(),
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}
