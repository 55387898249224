import React from 'react';
import { useFleets } from '../../context/fleetContext';
import useDropdown from '../../hooks/useDropdown';
import I18n from 'i18n-js';
import { useNavigate } from 'react-router-dom';

export default function ClientComponent() {
  const { fleets, currentFleet } = useFleets();
  const { dropdownRef, handleDropdownRefClick } = useDropdown();
  const navigate = useNavigate();

  const handleFleetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    navigate(`/${e.target.value}`);
  };

  return (
    <>
      {fleets.length > 1 && (
        <div className="client">
          <p className="clientLabel">{I18n.t('solar.menu.client')}</p>
          <details className="custom-select" ref={dropdownRef}>
            <summary className="radios" onClick={handleDropdownRefClick}>
              {fleets.map(({ slug, name }) => {
                return (
                  <input
                    type="radio"
                    key={slug}
                    name="fleet"
                    id={slug}
                    title={name}
                    value={slug}
                    checked={slug === currentFleet}
                    onChange={handleFleetChange}
                  />
                );
              })}
            </summary>
            <ul className="list fleetList">
              {fleets.map(({ slug, name }) => (
                <li key={slug}>
                  <label htmlFor={slug}>{name}</label>
                </li>
              ))}
            </ul>
          </details>
        </div>
      )}
    </>
  );
}
