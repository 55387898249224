import React, { createContext, useContext, ReactNode } from 'react';
import { UiVehicleOperatingMode, useVehicleOperatingModes } from '../hooks/useVehicleOperatingModes';
import { useParams } from 'react-router-dom';
import { useFleets } from './fleetContext';

interface VehicleOperatingModesContextProps {
  vehicleOperatingModes: UiVehicleOperatingMode[] | undefined;
  setMode: (day: string, id: number, reason: string) => Promise<void>;
}

const VehicleOperatingModesContext = createContext<VehicleOperatingModesContextProps | undefined>(undefined);

interface VehicleOperatingModesProviderProps {
  children: ReactNode;
}

export const VehicleOperatingModesProvider = ({ children }: VehicleOperatingModesProviderProps) => {
  const { vehicleId: vehicleIdStr } = useParams();
  const vehicleId = vehicleIdStr ? Number.parseInt(vehicleIdStr) : -1;
  const { currentFleet } = useFleets();
  const { vehicleOperatingModes, setMode } = useVehicleOperatingModes(currentFleet || '', vehicleId);

  return (
    <VehicleOperatingModesContext.Provider value={{ vehicleOperatingModes, setMode }}>
      {children}
    </VehicleOperatingModesContext.Provider>
  );
};

export const useVehicleOperatingModesContext = () => {
  const context = useContext(VehicleOperatingModesContext);
  if (context === undefined) {
    throw new Error('useVehicleOperatingModesContext must be used within a VehicleOperatingModesProvider');
  }
  return context;
};
