import { useRef, useEffect, useCallback } from 'react';

// Custom hook to manage the state and behavior of a dropdown component.

const useDropdown = () => {
  const dropdownRef = useRef<HTMLDetailsElement>(null);

  // Toggles the open state of the <details> element.
  // memoize the function to prevent unnecessary re-renders.
  const handleDropdownRefClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (dropdownRef.current) {
        const detailsElement = dropdownRef.current as HTMLDetailsElement;
        if (detailsElement.hasAttribute('open')) {
          detailsElement.removeAttribute('open');
        } else {
          detailsElement.setAttribute('open', '');
        }
      }
    },
    [dropdownRef],
  );

  // Closes the <details> element if a click is detected outside of it.
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        dropdownRef.current.removeAttribute('open');
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return {
    dropdownRef,
    handleDropdownRefClick,
  };
};

export default useDropdown;
