import React from 'react';
import { useOpenMenu } from '../../context/openMenuContext';
import MenuLink from './menuLink';
import Logo from './logo';
import { Page } from './menu';

export default function MenuContainer({ pages, children }: { pages: Page[]; children: React.ReactNode }) {
  const { isOpen } = useOpenMenu();
  return (
    <div className={`menu ${isOpen ? 'open' : 'closed'}`}>
      <Logo link={'sono.png'} name={'Sono Logo'} />
      <ul>
        {pages
          .filter((page) => !page.hidden)
          .map((page, idx) => (
            <MenuLink key={idx} {...page}>
              {page.title}
            </MenuLink>
          ))}
      </ul>
      {children}
    </div>
  );
}
