import { UserFleet } from '@solar-data/schemas/lib/owned-by/solar';
import { hirachie, ROLE } from '../constants/roles';
import allRolesMatch from '../utils/allRolesMatch';

const isGranted = (roles: ROLE[], fleets: UserFleet[], currentFleetSlug: string | undefined): boolean => {
  const adminFleet = fleets.find((f) => f.slug === 'admin');
  const currentFleet = fleets.find((f) => f.slug === currentFleetSlug);
  const currentFleetRole = currentFleet?.role;
  const adminFleetRole = adminFleet?.role;
  const isInAdminFleet = Boolean(adminFleet && (adminFleetRole === 'admin' || adminFleetRole === 'viewer'));
  const isInCurrentFleet = Boolean(currentFleet && (currentFleetRole === 'admin' || currentFleetRole === 'viewer'));

  const isSupperAdmin = isInAdminFleet && adminFleetRole === 'admin';
  const isGlobalAdmin = isInAdminFleet && adminFleetRole === 'viewer';
  const isFleetAdmin = isInCurrentFleet && currentFleetRole === 'admin';
  const isFleetViewer = isInCurrentFleet && currentFleetRole === 'viewer';
  const isGuest = !isFleetViewer && !isFleetAdmin && !isSupperAdmin && !isGlobalAdmin;

  if (isSupperAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.SUPER_ADMIN]);
  }
  if (isGlobalAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.GLOBAL_ADMIN]);
  }
  if (isFleetAdmin) {
    return allRolesMatch(roles, hirachie[ROLE.FLEET_ADMIN]);
  }
  if (isFleetViewer) {
    return allRolesMatch(roles, hirachie[ROLE.VIEWER]);
  }
  if (isGuest) {
    return allRolesMatch(roles, hirachie[ROLE.GUEST]);
  }

  return false;
};

export default isGranted;
