import React from 'react';
import I18n from 'i18n-js';
import en from './locales/en';
import de from './locales/de';
import 'moment/locale/de';
import moment from 'moment';

export enum Language {
  DE = 'de',
  EN = 'en',
}

I18n.locale =
  window.localStorage.getItem('sonoLocale') ??
  navigator.language.replace(/^([a-zA-Z]+)-.*$/, (all, lang) => lang.toLowerCase()) ??
  Language.EN;
I18n.defaultLocale = Language.EN;
I18n.fallbacks = true;
I18n.translations = {
  en,
  de,
};

moment.locale(I18n.locale);

/**
 * Extended version of I18n.t that can interpolate React elements into placeholders.
 */
function tx(scope: I18n.Scope, options: I18n.TranslateOptions): React.ReactNode {
  const elements: Record<string, any> = {};
  const sep = `[${Math.random()}]`;

  for (const [key, value] of Object.entries(options)) {
    if (value?.$$typeof?.description === 'react.element') {
      options[key] = `${sep}${key}${sep}`;
      elements[key] = value;
    }
  }

  return (
    <>
      {I18n.t(scope, options)
        .split(sep)
        .map((part, idx) => (
          <React.Fragment key={idx}>{idx % 2 ? elements[part] : part}</React.Fragment>
        ))}
    </>
  );
}

function currency(cents: number): string {
  return I18n.t('solar.euros.format', {
    amount: I18n.toNumber(cents / 100, {
      separator: I18n.t('solar.euros.separator'),
      delimiter: I18n.t('solar.euros.delimiter'),
      strip_insignificant_zeros: true,
    }),
  });
}

function date(yyyyMmDd: string): string {
  if (!yyyyMmDd || yyyyMmDd.split('-').length !== 3) {
    return yyyyMmDd;
  }
  const [year, month, day] = yyyyMmDd.split('-');
  return I18n.t('solar.date', { year, month, day });
}

const I18nx = {
  ...I18n,
  tx,
  currency,
  date,
  currentLocale: () => I18n.locale,
  setLocale: (locale: string) => {
    moment.locale(locale);
    window.localStorage.setItem('sonoLocale', locale);
    I18n.locale = locale;
  },
};

export default I18nx;
