import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export async function doFetch<T>(
  url: string,
  request: AxiosRequestConfig,
  setError: (error: string) => void,
  logout: () => void,
  consume: (data: T | undefined) => void = (data) => {},
) {
  try {
    consume(undefined);

    const resp = await axios(url, request);

    if (resp.status === 200) {
      consume(resp.data);
    } else if (resp.status === 401) {
      logout();
      window.location.href = '#/login';
    } else {
      throw resp.statusText;
    }
  } catch (e) {
    setError(`${e}`);
  }
}

export async function authFetch<T>(url: string, config: AxiosRequestConfig): Promise<T> {
  try {
    const res: AxiosResponse<T> = await axios(url, config);
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || error.message);
    } else {
      throw new Error('Unknown error');
    }
  }
}
