import React from 'react';

export default function HamburgerIcon() {
  return (
    <div className="hamburger">
      <span className="hamburgerLine"></span>
      <span className="hamburgerLine"></span>
      <span className="hamburgerLine"></span>
    </div>
  );
}
