import './footer.scss';
import React from 'react';
import I18n from 'i18n-js';

export default function Footer() {
  return (
    <footer>
      <p>{I18n.t('solar.footer.rightsReserved')}</p>
      <ul className="links">
        <li>
          <a id="footer__whisteblowerLink" href={I18n.t('solar.footer.whisteblowerLink')}>
            {I18n.t('solar.footer.whisteblowerLabel')}
          </a>
        </li>
        <li>
          <a id="footer__privacyLink" href={I18n.t('solar.footer.privacyLink')}>
            {I18n.t('solar.footer.privacyLabel')}
          </a>
        </li>
        <li>
          <a id="footer__imprintLink" href={I18n.t('solar.footer.imprintLink')}>
            {I18n.t('solar.footer.imprintLabel')}
          </a>
        </li>
        <li>
          <a id="footer__termsLink" href={I18n.t('solar.footer.termsLink')}>
            {I18n.t('solar.footer.termsLabel')}
          </a>
        </li>
      </ul>
    </footer>
  );
}
